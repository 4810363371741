import style from "./style.module.scss";
import { FormikTextInput } from "../../shared/components/formik/FormikTextInput";
import firebase from "firebase/app";

const FormComponent = () => {
  const handleLogout = () => {
    firebase.auth().signOut();
  };

  return (
    <div className={style.formPage}>
      <div className={style.heading}>
        <h2>Application Form for BOOKING ID.</h2>
        <button
          style={{ border: "none", fontSize: "20px", cursor: "pointer", fontWeight: "600" }}
          className="logBtn"
          onClick={handleLogout}
        >
          Log out
        </button>
      </div>
      <div className={style.container}>
        <FormikTextInput />
      </div>
    </div>
  );
};

export default FormComponent;
