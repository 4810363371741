import { FC } from "react";
import style from "./style.module.scss";
import { useHistory } from "react-router";

export const SwitchPortals: FC = () => {
  const history = useHistory();
  const handlePaymentPortal = () => {
    history.push("/purchase");
  };
  const handleCrmPortal = () => {
    history.push("/crm-portal");
  };
  return (
    <div className={style.switchPage}>
      <div className={style.switchBtn}>
        <button className={style.btn} onClick={handlePaymentPortal}>
          Pay Online
        </button>
      </div>
      <div className={style.switchBtn}>
        <button className={style.btn} onClick={handleCrmPortal}>
          CRM Portal
        </button>
      </div>
    </div>
  );
};
