import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCzZOV0leteREoePFHfTY-byGBT3Aw8OKU",
  authDomain: "abtik-crm-portal.firebaseapp.com",
  projectId: "abtik-crm-portal",
  storageBucket: "abtik-crm-portal.appspot.com",
  messagingSenderId: "950715842889",
  appId: "1:950715842889:web:e0af9ee42fcb66815f9a98",
  measurementId: "G-F373253965",
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
