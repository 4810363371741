import { makePhonePostService, makePostService } from "../../shared/service/api.services";
export interface IPersonalInfo {
  clientCode: string;
  statusTransEncData: any;
  statusTransId: string;
  payloadData: string;
  shaVerify: string;
}
export const performTransactionService = (clientCode: IPersonalInfo, statusTransEncData: IPersonalInfo) => {
  const url = "/SPTxtnEnquiry/getTxnStatusByClientxnId";
  return makePostService(url, {
    clientCode: clientCode,
    statusTransEncData: statusTransEncData,
  });
};
export const performPhonePeService = (payloadData: string, shaVerify: string) => {
  const url = "/apis/hermes/pg/v1/pay";
  return makePhonePostService(url, {
    payloadData: payloadData,
    shaVerify: shaVerify,
  });
};
