import axios, { AxiosResponse } from "axios";

export const makePost = (url: string, data?: unknown): Promise<AxiosResponse> => {
  return axios.post(url, data);
};
export const makePostService = (url: string, transactionData?: any): Promise<AxiosResponse> => {
  return axios({
    method: "post",
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      clientCode: transactionData.clientCode,
      statusTransEncData: transactionData.statusTransEncData,
    },
  });
};
export const makePhonePostService = (url: string, formData?: any): Promise<AxiosResponse> => {
  return axios({
    method: "post",
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-VERIFY": formData.shaVerify,
    },
    data: JSON.stringify({
      request: formData.payloadData,
    }),
  });
};
