import "./HeaderComponent.scss";
import exit from "../assets/photos/exit.png";

const HeaderComponent = () => {
  return (
    <div className="header">
      <div className="logo">
        <img
          className="companyLogo"
          src="https://abtikservices.com/wp-content/uploads/2023/01/duble-high-qulity.png"
          alt="logo"
        />
      </div>
      <div className="menuBar">
        <a href="https://abtikservices.com/">
          <img className="exitIcon" src={exit} alt="exit Icon" />
        </a>
      </div>
    </div>
  );
};

export default HeaderComponent;
