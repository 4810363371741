import { FC, useState } from "react";
import style from "./style.module.scss";
import { useFormik } from "formik";
import { performPhonePeService } from "../../services/services";
import CryptoJS from "crypto-js";
import { generateUniqueID } from "../../../utils/Utils";

export interface rightBoxModal {
  setCompanyName: Function;
  setPaymentAmount: Function;
}

const RightBoxComponent: FC<rightBoxModal> = ({ setCompanyName, setPaymentAmount }: rightBoxModal) => {
  const [data, setData] = useState({
    paymentLink: "",
    error: "",
  });
  const uniqueId = generateUniqueID();
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.clientName) {
      errors.clientName = "Required";
    } else if (values.clientName.length < 3) {
      errors.clientName = "Must be 3 characters or more";
    }
    if (!values.clientNumber) {
      errors.clientNumber = "Required";
    } else if (!/^[6-9]\d{9}$/.test(values.clientNumber)) {
      errors.clientNumber = "Invalid Indian Number";
    }
    if (!values.amount) {
      errors.amount = "Required";
    } else if (!/^\d*\.?\d*$/.test(values.amount)) {
      errors.amount = "Invalid Amount";
    } else if (values.amount.length > 9) {
      errors.amount = "Amount must be less than 1 lakhs";
    }
    if (!values.clientEmail) {
      errors.clientEmail = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.clientEmail)) {
      errors.clientEmail = "Invalid email address";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      clientName: "",
      clientNumber: "",
      clientEmail: "",
      amount: "",
    },
    validate,
    onSubmit: (values) => {
      const numberFormatted = values.amount + "00";
      const payload = {
        merchantId: "M1AQ6WYH6C1X",
        merchantTransactionId: uniqueId,
        merchantUserId: "AB" + values.clientNumber,
        amount: numberFormatted,
        redirectUrl: "https://webhook.site/redirect-url",
        redirectMode: "REDIRECT",
        callbackUrl: "https://webhook.site/callback-url",
        mobileNumber: values.clientNumber,
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      };
      setPaymentAmount(values.amount);
      const base64EncodedPayload = btoa(JSON.stringify(payload));
      const saltKey = "3be0d88d-c5a7-444e-8484-af3556d08d6b";
      const saltIndex = "1";
      const concatenatedString = base64EncodedPayload + "/pg/v1/pay" + saltKey;
      const sha256Hash = CryptoJS.SHA256(concatenatedString).toString(CryptoJS.enc.Hex);
      const finalResult = sha256Hash + "###" + saltIndex;
      performPhonePeService(base64EncodedPayload, finalResult)
        .then((response) => {
          setData({ ...data, paymentLink: response.data.data.instrumentResponse.redirectInfo.url });
          if (response.data.data.instrumentResponse.redirectInfo.url) {
            window.open(response.data.data.instrumentResponse.redirectInfo.url, "_blank");
          }
        })
        .catch((error) => {
          setData({ ...data, error: error.message });
        });
    },
  });
  return (
    <div className={style.rtBx}>
      <div className={style.header}>
        <img
          className={style.logo}
          src="https://abtikservices.com/wp-content/uploads/2023/08/logo2-03-1.png"
          alt="comLogo"
        />
        <h2 className={style.headerText}>Abtik</h2>
      </div>
      <div className={style.formBox}>
        <form onSubmit={formik.handleSubmit} className={style.form} encType="multipart/form-data">
          <div className={style.contactInfo}>
            <div className={style.clientDetails}>
              <label htmlFor={style.clientName}>Enter Your Name</label>
              <input
                id="clientName"
                name="clientName"
                type="text"
                placeholder="Enter Your Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.clientName}
                className={
                  formik.touched.clientName && formik.errors.clientName
                    ? `${style.textInput} ${style.error}`
                    : `${style.textInput}`
                }
              />
              {formik.touched.clientName && formik.errors.clientName ? (
                <div className={`${style.inputFeedback}`}>{formik.errors.clientName}</div>
              ) : null}
              <label htmlFor="clientNumber">Enter Your Number</label>
              <input
                id="clientNumber"
                name="clientNumber"
                type="tel"
                placeholder="Enter Your Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.clientNumber}
                className={
                  formik.touched.clientNumber && formik.errors.clientNumber
                    ? `${style.textInput} ${style.error}`
                    : `${style.textInput}`
                }
              />
              {formik.touched.clientNumber && formik.errors.clientNumber ? (
                <div className={`${style.inputFeedback}`}>{formik.errors.clientNumber}</div>
              ) : null}
              <label htmlFor="clientEmail">Enter Your Email Address</label>
              <input
                id="clientEmail"
                name="clientEmail"
                type="email"
                placeholder="Enter Your Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.clientEmail}
                className={
                  formik.touched.clientEmail && formik.errors.clientEmail
                    ? `${style.textInput} ${style.error}`
                    : `${style.textInput}`
                }
              />
              {formik.touched.clientEmail && formik.errors.clientEmail ? (
                <div className={`${style.inputFeedback}`}>{formik.errors.clientEmail}</div>
              ) : null}
              <label htmlFor="amount">Enter Amount</label>
              <input
                id="amount"
                name="amount"
                type="text"
                placeholder="Enter Amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                className={
                  formik.touched.amount && formik.errors.amount
                    ? `${style.textInput} ${style.error}`
                    : `${style.textInput}`
                }
              />
              {formik.touched.amount && formik.errors.amount ? (
                <div className={`${style.inputFeedback}`}>{formik.errors.amount}</div>
              ) : null}
            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default RightBoxComponent;
