import style from "./style.module.scss";
import PaymentGateway from "../PaymentGateway/PaymentGateway";

const HomeComponent = () => {
  return (
    <div className={style.hm}>
      <PaymentGateway />
    </div>
  );
};

export default HomeComponent;
