import { format } from "date-fns";
import { APP_ROUTES } from "../constants/global.constants";
export const getCrmAccess = (data: string) => {
  if (data === APP_IDS.CRM_PORTAL) {
    return true;
  } else if (data === APP_IDS.PAYMENT_METHOD) {
    return false;
  }
};
export const APP_IDS = {
  PAYMENT_METHOD: "PAYMENT_METHOD",
  CRM_PORTAL: "CRM_PORTAL",
  SELECTION_PORTAL: "SELECTION_PORTAL",
};
export const getDdMmYyyyDate = (date: Date) => {
  return format(date, "dd/MM/yyyy");
};
export const getAppId = () => {
  const app = APP_ROUTES.filter((app) => window.location.pathname.startsWith(app.pathname));
  return app?.length ? app[0].appId : APP_IDS.SELECTION_PORTAL;
};
export const generateUniqueID = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomString = Math.random().toString(36).substr(2, 5);
  const uniqueID = timestamp + randomString;
  return uniqueID;
};
