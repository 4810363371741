import { FC } from "react";
import style from "./style.module.scss";
import { IoCall, IoMail } from "react-icons/io5";

export interface leftBoxModal {
  companyNameData: string;
  paymentAmountData: string;
}

const LeftBoxComponent: FC<leftBoxModal> = ({ companyNameData, paymentAmountData }: leftBoxModal) => {
  return (
    <div className={style.ltC}>
      <div className={style.ltBx}>
        <h2>Payment Request From Abtik</h2>
        <div className={style.pay}>
          <h3>PAYMENT FOR</h3>
          <p>Consultancy Services from Abtik Group of Companies</p>
        </div>
        <div className={style.amt}>
          <h3>AMOUNT PAYABLE</h3>
          <p className={style.money}>INR: {paymentAmountData ? paymentAmountData : "--"}</p>
        </div>
      </div>
      <div className={style.ltFt}>
        <span>
          For any queries, contact <strong>ABTIK</strong>
        </span>
        <span className={style.num}>
          <IoCall /> <a href="tel: +91 89281 38434">+91 89281 38434</a>
        </span>
        <span className={style.mail}>
          <IoMail /> <a href="mailto:info@abtikservices.com">info@abtikservices.com</a>
        </span>
      </div>
    </div>
  );
};

export default LeftBoxComponent;
