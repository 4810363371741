import { useState } from "react";
import LeftBoxComponent from "./LeftBox/LeftBox";
import RightBoxComponent from "./RightBox/RightBox";

const PaymentGateway = () => {
  const [companyNameData, setCompanyNameData] = useState<any>({
    companyName: "",
    paymentAmount: "",
  });
  return (
    <>
      <LeftBoxComponent
        companyNameData={companyNameData.companyName}
        paymentAmountData={companyNameData.paymentAmount}
      />
      <RightBoxComponent
        setCompanyName={(e: string) => setCompanyNameData({ companyName: e })}
        setPaymentAmount={(e: string) => setCompanyNameData({ paymentAmount: e })}
      />
    </>
  );
};

export default PaymentGateway;
// fc8ba81b372d8147a16a1f227c2969bffbb3dc406819ff9e3daafa27b9c69b6e###1 eyJtZXJjaGFudElkIjoiTTFBUTZXWUg2QzFYIiwibWVyY2hhbnRUcmFuc2FjdGlvbklkIjoiTVQ3ODUwNTkwMDY4MTg4MTA0IiwibWVyY2hhbnRVc2VySWQiOiJNVUlEMTIzIiwiYW1vdW50IjoxMDAsInJlZGlyZWN0VXJsIjoiaHR0cHM6Ly93ZWJob29rLnNpdGUvcmVkaXJlY3QtdXJsIiwicmVkaXJlY3RNb2RlIjoiUkVESVJFQ1QiLCJjYWxsYmFja1VybCI6Imh0dHBzOi8vd2ViaG9vay5zaXRlL2NhbGxiYWNrLXVybCIsIm1vYmlsZU51bWJlciI6IjkxNzQ4MTg0MDUiLCJwYXltZW50SW5zdHJ1bWVudCI6eyJ0eXBlIjoiUEFZX1BBR0UifX0=
