import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import HomeComponent from "./Component/Home/Home";
import { Suspense, lazy, useEffect, useState } from "react";
import Spinner from "./shared/components/Spinner";
import HeaderComponent from "./shared/components/HeaderComponent";
import FooterComponent from "./shared/components/FooterComponent";
import LoginComponent from "./Component/Login/LoginComponent";
import FormComponent from "./Component/FormikComponent/FormComponent";
import firebase from "firebase/app";
import { SwitchPortals } from "./Component/SwitchPortals/SwitchPortals";
const NotFound = lazy(() => import("./shared/components/NotFound"));

export const SelectionPortalRoutes: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user: any) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);
  return (
    <Router>
      <div style={{ height: "60px" }}>
        <HeaderComponent />
      </div>
      <>
        <Suspense fallback={<Spinner />}>
          <div
            style={{
              height: "calc(100vh - 100px)",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <Switch>
              <Route exact={true} path="/">
                <SwitchPortals />
              </Route>
              <Route exact={true} path="/purchase">
                <HomeComponent />
              </Route>
              <Route exact={true} path="/crm-portal">
                {user ? <FormComponent /> : <LoginComponent />}
              </Route>

              <Route component={NotFound} />
            </Switch>
          </div>
        </Suspense>
      </>
      <div className="ftSection" style={{ height: "40px", background: "#fff" }}>
        <FooterComponent />
      </div>
    </Router>
  );
};
