import style from "./style.module.scss";
import vector from "../../shared/assets/photos/vector.png";
import emailIcon from "../../shared/assets/photos/email-icon.png";
import { FC, useState } from "react";
import { auth } from "../../firebase";

export interface ILoginModal {
  status?: any;
}

const LoginComponent: FC<ILoginModal> = () => {
  const [active, setActive] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any>(null);
  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error: any) {
      setError(error);
    }
  };
  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await auth.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div className={style.login}>
      {active ? (
        <>
          <div className={style.left}>
            <img src={vector} className={style.vecImg} alt="vector_img" />
            <div className={style.heading}>
              <h2 className={style.headText}>Welcome to Abtik Services Booking</h2>
            </div>
          </div>
          <div className={style.right}>
            <div className={style.content}>
              <div className={style.logo}>
                <img
                  className={style.logoImg}
                  src="https://abtikservices.com/wp-content/uploads/2023/06/LOGO-02-02.png"
                  alt="company_logo"
                />
              </div>
              <h2 className={style.mainHeading}>Welcome to Abtik Group of Companies</h2>
              <p className={style.paraText}>Create your account for using the crm portal</p>
              <form className="auth-form" onSubmit={handleSignUp}>
                <div className={style.formContainer}>
                  <div className={style.inputBox}>
                    <label className={style.email}>Email</label>
                    <input
                      placeholder="Enter Your Email ID"
                      className={style.inputField}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      type="email"
                    />
                    <img className={style.emailIcon} src={emailIcon} alt="email_icon" />
                  </div>
                  <div className={style.inputBox}>
                    <label className={style.email}>password</label>
                    <input
                      placeholder="Enter Your Password"
                      className={style.inputField}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      type="password"
                    />
                    <img className={style.emailIcon} src={emailIcon} alt="email_icon" />
                  </div>
                  {/* <div className={style.forgetBox}>
                    <div className={style.checkbox}>
                      <input className={style.checkboxField} id="remember" type="checkbox" />
                      <label htmlFor="remember" className={style.checkBoxLabel}>
                        Remember me
                      </label>
                    </div>
                    <div className={style.forgetText}>Forget Password?</div>
                  </div> */}
                </div>
                <div className={style.buttonField}>
                  <button className={style.btn} type="submit">
                    Sign In
                  </button>
                </div>
                <p className={style.signUp}>
                  Already user{" "}
                  <span className={style.signUpBtn} onClick={() => setActive(!active)}>
                    Login Now
                  </span>
                </p>
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={style.left}>
            <img src={vector} className={style.vecImg} alt="vector_img" />
            <div className={style.heading}>
              <h2 className={style.headText}>Welcome to Abtik Services Booking</h2>
            </div>
          </div>
          <div className={style.right}>
            <div className={style.content}>
              <div className={style.logo}>
                <img
                  className={style.logoImg}
                  src="https://abtikservices.com/wp-content/uploads/2023/06/LOGO-02-02.png"
                  alt="company_logo"
                />
              </div>
              <h2 className={style.mainHeading}>Welcome to Abtik Group of Companies</h2>
              <p className={style.paraText}>One Stop Solution for Booking Generation and Admin Team</p>
              <form className="auth-form" onSubmit={handleLogin}>
                <div className={style.formContainer}>
                  <div className={style.inputBox}>
                    <label className={style.email}>Email</label>
                    <input
                      placeholder="Enter Your Email ID"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      className={style.inputField}
                      type="email"
                    />
                    <img className={style.emailIcon} src={emailIcon} alt="email_icon" />
                  </div>
                  <div className={style.inputBox}>
                    <label className={style.email}>password</label>
                    <input
                      placeholder="Enter Your Password"
                      className={style.inputField}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      type="password"
                    />
                    <img className={style.emailIcon} src={emailIcon} alt="email_icon" />
                  </div>
                  {/* <div className={style.forgetBox}>
                    <div className={style.checkbox}>
                      <input className={style.checkboxField} id="remember" type="checkbox" />
                      <label htmlFor="remember" className={style.checkBoxLabel}>
                        Remember me
                      </label>
                    </div>
                    <div className={style.forgetText}>Forget Password?</div>
                  </div> */}
                </div>
                <div className={style.buttonField}>
                  <button className={style.btn} type="submit">
                    Login
                  </button>
                </div>
                <p className={style.signUp}>
                  Don't have an account?{" "}
                  <span className={style.signUpBtn} onClick={() => setActive(!active)}>
                    SignUp Now
                  </span>
                </p>
              </form>
              {error && <p className={style.authError}>{error.message}</p>}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginComponent;
