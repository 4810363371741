import { ScaleLoader } from "react-spinners";

export interface ISpinnerProps {
  isInner?: boolean;
}
const Spinner: React.FC<ISpinnerProps> = ({ isInner = true }: ISpinnerProps) => {
  if (isInner) {
    return (
      <div>
        <ScaleLoader color="#36d7b7" />
      </div>
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <ScaleLoader color="#36d7b7" />
    </div>
  );
};

export default Spinner;
