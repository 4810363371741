import { useFormik } from "formik";
import "./style.scss";
import { db } from "../../../firebase";
const validate = (values: any) => {
  const errors: any = {};
  if (!values.clientName) {
    errors.clientName = "Required";
  } else if (values.clientName.length > 15) {
    errors.clientName = "Must be 15 characters or less";
  }
  if (!values.screenshotData) {
    errors.screenshotData = "Required";
  }
  if (!values.managerName) {
    errors.managerName = "Required";
  } else if (values.managerName.length > 15) {
    errors.managerName = "Must be 15 characters or less";
  }
  if (!values.remarkData) {
    errors.remarkData = "Required";
  } else if (values.remarkData.length > 150) {
    errors.remarkData = "Must be 15 characters or less";
  }
  if (!values.closedLeadInfo) {
    errors.closedLeadInfo = "Required";
  } else if (values.closedLeadInfo.length > 15) {
    errors.closedLeadInfo = "Must be 15 characters or less";
  }
  if (!values.clientNumber) {
    errors.clientNumber = "Required";
  } else if (values.clientNumber.length > 10) {
    errors.clientNumber = "Invalid Phone Number";
  }
  if (!values.totalAmount) {
    errors.totalAmount = "Required";
  } else if (values.totalAmount.length > 10) {
    errors.totalAmount = "Invalid Phone Number";
  }
  if (!values.advPayment) {
    errors.advPayment = "Required";
  } else if (values.advPayment.length > 10) {
    errors.advPayment = "Invalid Phone Number";
  }
  if (!values.afterFund) {
    errors.afterFund = "Required";
  } else if (values.afterFund.length > 10) {
    errors.afterFund = "Invalid Phone Number";
  }
  if (!values.clientEmail) {
    errors.clientEmail = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.clientEmail)) {
    errors.clientEmail = "Invalid email address";
  }
  if (!values.managerEmail) {
    errors.managerEmail = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.managerEmail)) {
    errors.managerEmail = "Invalid email address";
  }
  return errors;
};

export const FormikTextInput = () => {
  const formik = useFormik({
    initialValues: {
      clientName: "",
      clientNumber: "",
      clientEmail: "",
      managerName: "",
      managerEmail: "",
      screenshotData: "",
      totalAmount: "",
      advPayment: "",
      afterDocument: "",
      afterFund: "",
      remarkData: "",
      closedLeadInfo: "",
    },
    validate,
    onSubmit: (values) => {
      db.collection("data")
        .add({
          formData: values,
        })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className="form" encType="multipart/form-data">
      <div className="contactInfo">
        <div className="clientDetails">
          <label htmlFor="clientName">Enter Client Name</label>
          <input
            id="clientName"
            name="clientName"
            type="text"
            placeholder="Enter Client Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.clientName}
            className={formik.touched.clientName && formik.errors.clientName ? "text-input error" : "text-input "}
          />
          {formik.touched.clientName && formik.errors.clientName ? (
            <div className="input-feedback">{formik.errors.clientName}</div>
          ) : null}
          <label htmlFor="clientNumber">Enter Client Number</label>
          <input
            id="clientNumber"
            name="clientNumber"
            type="number"
            placeholder="Enter Client Number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.clientNumber}
            className={formik.touched.clientNumber && formik.errors.clientNumber ? "text-input error" : "text-input "}
          />
          {formik.touched.clientNumber && formik.errors.clientNumber ? (
            <div className="input-feedback">{formik.errors.clientNumber}</div>
          ) : null}
          <label htmlFor="clientEmail">Enter Client Email Address</label>
          <input
            id="clientEmail"
            name="clientEmail"
            type="email"
            placeholder="Enter Client Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.clientEmail}
            className={formik.touched.clientEmail && formik.errors.clientEmail ? "text-input error" : "text-input "}
          />
          {formik.touched.clientEmail && formik.errors.clientEmail ? (
            <div className="input-feedback">{formik.errors.clientEmail}</div>
          ) : null}
        </div>
        <div className="managerDetails">
          <label htmlFor="managerName">Enter Manager Name</label>
          <input
            id="managerName"
            name="managerName"
            type="text"
            placeholder="Enter Manager Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.managerName}
            className={formik.touched.managerName && formik.errors.managerName ? "text-input error" : "text-input "}
          />
          {formik.touched.managerName && formik.errors.managerName ? (
            <div className="input-feedback">{formik.errors.managerName}</div>
          ) : null}
          <label htmlFor="managerEmail">Enter Manager Email Address</label>
          <input
            id="managerEmail"
            name="managerEmail"
            type="email"
            placeholder="Enter Manager Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.managerEmail}
            className={formik.touched.managerEmail && formik.errors.managerEmail ? "text-input error" : "text-input "}
          />
          {formik.touched.managerEmail && formik.errors.managerEmail ? (
            <div className="input-feedback">{formik.errors.managerEmail}</div>
          ) : null}
        </div>
      </div>
      <div className="screenshotInfo">
        <label htmlFor="screenshotData">Enter Screenshot of the Payment Details:</label>
        <input
          id="screenshotData"
          name="screenshotData"
          type="file"
          placeholder="Enter Screenshot"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.screenshotData}
          className={
            formik.touched.screenshotData && formik.errors.screenshotData
              ? "text-input increaseWidth error"
              : "text-input "
          }
        />
        {formik.touched.screenshotData && formik.errors.screenshotData ? (
          <div className="input-feedback">{formik.errors.screenshotData}</div>
        ) : null}
      </div>
      <div className="totalBookingAmt">
        <label htmlFor="totalAmount">Enter Total Booking Amount:</label>
        <input
          id="totalAmount"
          name="totalAmount"
          type="number"
          placeholder="Enter Total Amount"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.totalAmount}
          className={
            formik.touched.totalAmount && formik.errors.totalAmount ? "text-input increaseWidth error" : "text-input "
          }
        />
        {formik.touched.totalAmount && formik.errors.totalAmount ? (
          <div className="input-feedback">{formik.errors.totalAmount}</div>
        ) : null}
      </div>
      <div className="paymentStages">
        <h2>Payment Stages</h2>
        <div className="paymentMainContainer">
          <div className="paymentContainer">
            <label htmlFor="advPayment">Enter Advance Payment:</label>
            <input
              id="advPayment"
              name="advPayment"
              type="number"
              placeholder="Enter Advance Payment"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.advPayment}
              className={formik.touched.advPayment && formik.errors.advPayment ? "text-input error" : "text-input "}
            />
            {formik.touched.advPayment && formik.errors.advPayment ? (
              <div className="input-feedback">{formik.errors.advPayment}</div>
            ) : null}
          </div>
          <div className="paymentContainer">
            <label htmlFor="afterDocument">Enter Amount After Documentation(Pitch, DPR, Financial):</label>
            <input
              id="afterDocument"
              name="afterDocument"
              type="number"
              placeholder="Enter Amount after Documentation"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.afterDocument}
              className={
                formik.touched.afterDocument && formik.errors.afterDocument ? "text-input error" : "text-input "
              }
            />
          </div>
          <div className="paymentContainer">
            <label htmlFor="afterFund">Enter Amount After Fund Disbursement:</label>
            <input
              id="afterFund"
              name="afterFund"
              type="number"
              placeholder="Enter Amount After Disbursement"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.afterFund}
              className={formik.touched.afterFund && formik.errors.afterFund ? "text-input error" : "text-input "}
            />
            {formik.touched.afterFund && formik.errors.afterFund ? (
              <div className="input-feedback">{formik.errors.afterFund}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="companyDetails">
        <label htmlFor="remarkData">Enter Any remark About the Company</label>
        <input
          id="remarkData"
          name="remarkData"
          type="text"
          placeholder="Enter Remark"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.remarkData}
          className={formik.touched.remarkData && formik.errors.remarkData ? "text-input error" : "text-input "}
        />
        {formik.touched.remarkData && formik.errors.remarkData ? (
          <div className="input-feedback">{formik.errors.remarkData}</div>
        ) : null}
        <label htmlFor="closedLeadInfo">Lead Closed By:</label>
        <input
          id="closedLeadInfo"
          name="closedLeadInfo"
          type="text"
          placeholder="Enter Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.closedLeadInfo}
          className={formik.touched.closedLeadInfo && formik.errors.closedLeadInfo ? "text-input error" : "text-input "}
        />
        {formik.touched.closedLeadInfo && formik.errors.closedLeadInfo ? (
          <div className="input-feedback">{formik.errors.closedLeadInfo}</div>
        ) : null}
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};
