import "./FooterComponent.scss";

const FooterComponent = () => {
  return (
    <div className="footer">
      <div className="ftLeft">© Copyright 2023. All Rights Reserved. ABTIKSERVICES</div>
    </div>
  );
};

export default FooterComponent;
